import styles from "../styles/deschutes.module.scss"
import cblockStyles from "../styles/commons/cblock.module.scss"
import checksStyles from "../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../components/layout/layout"
import StartDemo from "../components/start-demo/start-demo"
import Resource from "../components/resource/resource"
import FsLightbox from "fslightbox-react"
import { ProductsUseCases } from "../components/products-use-cases/products-use-cases"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import withAppContext from "../contexts/app.context"
import { ENV_CONSOLE_BASE_URL } from "../utils/secrets"
import ArrowAltRightSvg from "../components/svg/arrow-alt-right"
import { Helper } from "../utils/helper"
import PlaySvg from "../components/svg/play"

const DeschutesPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: { relativePath: { eq: "deschutes" }, category: { eq: "seo" } }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const overviewLink = "https://player.vimeo.com/video/448641434?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const moreInfoTiles = [
    {
      title:
        "Volterra Unveils Distributed Load Balancer, API Gateway and API Security",
      internalUrl:
        "/company/news/volterra-unveils-distributed-load-balancer-api-gateway-and-api-security",
      lang: "",
    },
    {
      title: "Multi-Cluster Load Balancer & API Gateway",
      internalUrl:
        "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway",
      lang: "",
    },
    {
      title: "Automated Zero-Trust & API Security",
      internalUrl: "/products/voltmesh-automated-zero-trust-and-api-security",
      lang: "",
    },
    {
      type: "paper",
      externalUrl:
        "/resources/doc/80304_New_Paradigm_for_Neworking_and_Securing_Modern_Apps_Template-converted.pdf",
      title: "New Paradigm for Networking and Securing Modern Apps",
    },
    {
      type: "paper",
      externalUrl:
        "/resources/doc/Volterra_Networking_and_Security_for_Modern_Apps_Brief.pdf",
      title: "Networking & Security for Modern Apps",
    },
    {
      type: "blog",
      internalUrl:
        "/resources/blog/apps-are-becoming-distributed-what-about-your_infra",
      title: "Apps Are Becoming Distributed, What about Your Infra?",
    },
    {
      type: "blog",
      internalUrl:
        "/resources/blog/why-ml-is-mandatory-for-effective-api-security",
      title: "Why ML Is Mandatory for Effective API Security",
    },
  ]

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/deschutes"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__inner}>
          <div className={styles.header__copy}>
            <h1 className={styles.header__title}>
              The Future of Networking & Security for Modern Apps
            </h1>
            <div className={styles.header__cta}>
              <a
                aria-label="Start for Free"
                href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                className={"btn btn-primary " + styles.header__btn}>
                Start for Free
              </a>
            </div>
          </div>
          <div className={styles.header__visual}>
            <div
              className={styles.header__video}
              onClick={openSelectedVideo}
              aria-label="Open video">
              <div className={styles.header__videoOverlay}>
                <PlaySvg className={styles.header__videoPlay} />
              </div>
              <img
                src="/img/products/deschutes-video-preview.jpg"
                alt="Video preview"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits of ADN */}
      <section className={cblockStyles.cblock}>
        <div className={cblockStyles.cblock__narrow}>
          <div className="container">
            <div className="row align-items-center mx-n4">
              <div className="col-lg-6 px-4">
                <div className={styles.introbox}>
                  <h2 className={`mb-4 ${styles.headtitle}`}>
                    Modern apps are harder to connect & secure
                  </h2>
                  <p className={`${cblockStyles.cblock__intro}`}>
                    There are fundamental challenges DevOps and NetOps teams
                    have in deploying and operating modern microservices- and
                    API-based apps:
                  </p>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <ul
                  className={`${styles.challenges} ${checksStyles.circlechecks} ${checksStyles.circlechecks__neg}`}>
                  <li className={checksStyles.circlechecks__item}>
                    Microservices across multiple clusters in one or more clouds
                    <ArrowAltRightSvg />
                    <strong>sprawl of services and infra</strong>
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Latency and performance of APIs across one or more clouds
                    <ArrowAltRightSvg />
                    <strong>reduced user experience</strong>
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    APIs and distributed microservices
                    <ArrowAltRightSvg />
                    <strong>
                      make zero-trust security very hard for DevSecOps
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Networking & Security for moderns apps */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={styles.headtitle}>
              VoltMesh is networking & security for <br />
              modern, distributed apps
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ProductsUseCases threePerRow="true">
              <div
                title="Distributed load balancer + API GW + WAF"
                link={
                  "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway"
                }
                imgurl="/img/products/apps-icon.svg"
                imgalt="Abstract app icon">
                <ul className={`mt-4 ${checksStyles.circlechecks}`}>
                  <li className={checksStyles.circlechecks__item}>
                    Integrated stack per cluster
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Centralized management
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    End-to-end visibility + policy control
                  </li>
                </ul>
              </div>
              <div
                title="Automated Zero-Trust + API Security"
                link={
                  "/products/voltmesh-automated-zero-trust-and-api-security"
                }
                imgurl="/img/products/security-alt2-icon.svg"
                imgalt="Chekered shield icon">
                <ul className={`mt-4 ${checksStyles.circlechecks}`}>
                  <li className={checksStyles.circlechecks__item}>
                    Auto-discovery of APIs + behavior
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Policy applied to whitelisted APIs
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Automated zero-trust at the API-level
                  </li>
                </ul>
              </div>
              <div
                title="Global app delivery network (ADN)"
                link={"/solutions/network/app-delivery-network"}
                imgurl="/img/products/cloud-connections-icon.svg"
                imgalt="Connected cloud icon">
                <ul className={`mt-4 ${checksStyles.circlechecks}`}>
                  <li className={checksStyles.circlechecks__item}>
                    Network-hosted workloads
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    LB + API gateway + K8s runtime
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Workloads processed + delivered closer to users
                  </li>
                </ul>
              </div>
            </ProductsUseCases>
          </div>
        </div>
      </section>

      {/* More Information */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>More Information</h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <div className="row">
              {moreInfoTiles.map(tile => (
                <div
                  key={tile.title}
                  className="col-12 col-sm-6 col-md-4 col-xl-3">
                  <Resource
                    title={tile.title}
                    type={tile.type}
                    internalUrl={tile.internalUrl}
                    externalUrl={tile.externalUrl}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <StartDemo />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
    </Layout>
  )
}

export default withAppContext(DeschutesPage)
