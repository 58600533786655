import React from "react"

const ArrowAltRightSvg = () => (
  <svg
    width="12px"
    height="9px"
    viewBox="0 0 12 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Deschutes-Iteration_3-updated"
        transform="translate(-852.000000, -949.000000)"
        fill="#0F1E57">
        <g id="Group-4" transform="translate(768.000000, 917.000000)">
          <text
            id="Microservices-across"
            fontFamily="DM Sans"
            fontSize="16"
            fontWeight="normal"
            line-spacing="24">
            <tspan x="26" y="16">
              Microservices across multiple clusters in one or more
            </tspan>
            <tspan x="26" y="40">
              clouds
            </tspan>
          </text>
          <path
            d="M91.7843055,32.0885912 L91.8535534,32.1464466 L95.8535534,36.1464466 L95.8654113,36.1587136 C95.8770608,36.1711813 95.8880797,36.1842458 95.8984173,36.1978561 L95.8535534,36.1464466 C95.8803877,36.1732809 95.9035342,36.2024064 95.922993,36.2331935 C95.9299807,36.2443175 95.9367067,36.2559533 95.9429657,36.2678728 C95.9502504,36.2817243 95.9566485,36.2954611 95.9623894,36.3094049 C95.9675915,36.3219749 95.9724419,36.3352631 95.9767316,36.3488008 C95.981552,36.3641653 95.9856039,36.3795257 95.9889143,36.3950129 C95.9912332,36.405629 95.9932062,36.4166163 95.9948142,36.4277219 C95.9973429,36.445461 95.9989382,36.4629328 95.9996194,36.4804335 C95.9998635,36.4863952 96,36.4931817 96,36.5 L95.9995793,36.5205695 C95.998877,36.5377348 95.9972949,36.5548708 95.9948333,36.5719041 L96,36.5 C96,36.536097 95.9961748,36.5713007 95.9889078,36.6052277 C95.9856039,36.6204743 95.981552,36.6358347 95.9767587,36.6510116 C95.9724419,36.6647369 95.9675915,36.6780251 95.9622078,36.6910366 C95.9566485,36.7045389 95.9502504,36.7182757 95.943195,36.7317582 C95.9367067,36.7440467 95.9299807,36.7556825 95.922812,36.7670103 C95.9192668,36.7727019 95.9154054,36.7785364 95.9114088,36.7843055 L95.8984173,36.8021439 C95.8880797,36.8157542 95.8770608,36.8288187 95.8654113,36.8412864 L95.8535534,36.8535534 L91.8535534,40.8535534 C91.6582912,41.0488155 91.3417088,41.0488155 91.1464466,40.8535534 C90.9728803,40.679987 90.9535951,40.4105626 91.0885912,40.2156945 L91.1464466,40.1464466 L94.292,37 L84.5,37 C84.2238576,37 84,36.7761424 84,36.5 C84,36.2545401 84.1768752,36.0503916 84.4101244,36.0080557 L84.5,36 L94.294,36 L91.1464466,32.8535534 C90.9728803,32.679987 90.9535951,32.4105626 91.0885912,32.2156945 L91.1464466,32.1464466 C91.320013,31.9728803 91.5894374,31.9535951 91.7843055,32.0885912 Z"
            id="Combined-Shape"
            fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowAltRightSvg
