import styles from "./resource.module.scss"
import React from "react"
import { Link } from "gatsby"
import { Helper } from "../../utils/helper"
import ArrowSvg from "../svg/arrow"
import { RES_PODCAST, RES_VIDEO, RES_WEBINAR } from "../../utils/constants"

const Resource = ({ title, type, externalUrl, slug, lang, internalUrl }) => {
  let badgeClass
  if (type) {
    badgeClass = `topic__badge${type.charAt(0).toUpperCase()}${type.slice(1)}`
  }

  const getLinkTextByType = type => {
    switch (type) {
      case RES_VIDEO:
      case RES_WEBINAR:
        return "Watch now"

      case RES_PODCAST:
        return "Listen now"

      default:
        return "Read more"
    }
  }

  //-- Trigger video play Google Tag Manager custom event
  const videoTrigger = (event, externalUrl, type) => {
    if (type === RES_VIDEO) {
      Helper.triggerGtagVideo(externalUrl)
    }
  }

  return (
    <div className={styles.topic__wrapper}>
      <div className={styles.topic}>
        {type && (
          <span className={`${styles.topic__badge} ${styles[badgeClass]}`}>
            {Helper.titleCase(type)}
          </span>
        )}
        <p
          className={`${styles.topic__title}
            ${type ? "" : styles.topic__titlePadded}
          `}
          ves-e2e-test="resource-center/topic-title">
          {title}
        </p>

        {lang && (
          <i
            className={styles.topic__flag}
            style={{
              backgroundImage: `url('/img/company/flags/${lang}-flag.png')`,
            }}
          />
        )}

        {externalUrl && (
          <a
            onClick={event => videoTrigger(event, externalUrl, type)}
            aria-label={getLinkTextByType(type)}
            className={styles.topic__link}
            target="_blank"
            rel="noopener noreferrer"
            href={externalUrl}>
            {getLinkTextByType(type)}
            <ArrowSvg />
          </a>
        )}
        {slug && (
          <Link
            aria-label="Read More"
            className={styles.topic__link}
            to={`/resources/blog/${slug}`}>
            Read more
            <ArrowSvg />
          </Link>
        )}
        {internalUrl && (
          <Link
            aria-label="Read More"
            className={styles.topic__link}
            to={internalUrl}>
            Read more
            <ArrowSvg />
          </Link>
        )}
      </div>
    </div>
  )
}

export default Resource
